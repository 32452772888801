<!-- Mto particular de Miscelaneas -->

<template>
  <div class="miscM" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
       persistent>

        <template v-slot:controles="{}">

          <div>

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">
              <div class="contenedor" style="width:1200px">

                <!-- <div v-html="info"></div> -->
                <!-- Botones Mto -->
                <!-- <div class="pt-2 d-flex justify-left" style="justify-content:space-between;width:360px"> -->
                <div v-if="!readOnly" class="pt-2 d-flex justify-left">

                  <!-- Mto -->
                  <baseBtraMto class="conflex"
                    v-if="!readOnly"
                    :perm="permMto"
                    :modulo="btMtoCfg"
                    :estado="estado"
                    @onEvent="execAccion">
                  </baseBtraMto>

                  <!-- Botones Copia y Pega .. controlar pega solo en alta y si ha copiado antes y copy en ver -->
                  <div class="conflex" style="margin-left: 5px">
                    <v-btn fab depressed x-small color="blue-grey lighten-4" class="pl-3" v-if="noEdit"
                        :disabled="!noEdit"
                        @click="copy()">copia
                    </v-btn>
                    <v-btn fab depressed x-small color="blue-grey lighten-4" class="pl-3" v-if="copiado"
                        :disabled="!(!noEdit && copiado)"
                        @click="pega()">pega
                    </v-btn>
                  </div>
                  <!-- --------------------------------------------------------- -->

                  <!-- Extra -->
                  <div class="conflex" style="margin-left: 50px">
                    <baseBtraExtra class="conflex"
                      :permExtra="permExtra"
                      :modulo="btExtCfg"
                      @onEvent="execAccion">
                    </baseBtraExtra>
                  </div>

                </div>

                <!-- Controles del Mto -->
                <div class="conflex" style="padding-top:10px">
                  <div class="columna" style="width:35%">

                    <div class="conflex">
                      <v-select
                        style="flex: 1 1 80%"
                        v-bind="$select"
                        v-model="ct.tipo[2]"
                        :items="$store.state.datos_iniciales.misctipo"
                        :disabled="noEdit"
                        item-value="id"
                        item-text="name">
                      </v-select>

                      <v-checkbox
                        style="flex: 1 1 20%;padding-left:10px"
                        v-bind="$checkbox"
                        v-model="ct.baja[2]"
                        :label="ct.baja[1]"
                        :disabled="noEdit">
                      </v-checkbox>
                    </div>

                    <div class="cab" style="margin-top:5px">DATOS GENERALES</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <uiText
                        v-model="ct.name[2]"
                        styleInput="font-weight: bold;"
                        :label="ct.name[1]"
                        :disabled="noEdit"
                        uCase>
                      </uiText>

                      <uiText
                        v-model="ct.dire[2]"
                        :label="ct.dire[1]"
                        :disabled="noEdit">
                      </uiText>

                      <ctrlF
                        :ct="ct.loc"
                        :edicion="!noEdit">
                      </ctrlF>

                      <div class="conflex">
                        <uiText
                          style="width:50%"
                          v-model="ct.cp[2]"
                          :label="ct.cp[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:50%"
                          v-model="ct.cif[2]"
                          :label="ct.cif[1]"
                          :disabled="noEdit"
                          format="cif">
                        </uiText>
                      </div>
                    </v-sheet>

                    <div class="cab">DATOS DE CONTACTO</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <uiText
                        v-model="ct.cont1[2]"
                        :label="ct.cont1[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiText
                        v-model="ct.tlf1[2]"
                        :label="ct.tlf1[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiText
                        v-model="ct.tlf2[2]"
                        :label="ct.tlf2[1]"
                        :disabled="noEdit">
                      </uiText>

                      <div style="conflex">
                        <uiText
                          v-model="ct.movil[2]"
                          :label="ct.movil[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          v-model="ct.fax1[2]"
                          :label="ct.fax1[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <uiText
                        v-model="ct.email1[2]"
                        :label="ct.email1[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiUrl
                        v-model="ct.url[2]"
                        :label="ct.url[1]"
                        :disabled="noEdit">
                      </uiUrl>

                    </v-sheet>
                  </div>

                  <div class="columna" style="width:30%">
                    <div class="cab">ADMINISTRACIÓN</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <!-- <div class="columna"> -->
                        <div style="conflex">
                          <v-checkbox
                            style="flex: 1 1 50%"
                            v-bind="$checkbox"
                            v-model="ct.cvarios[2]"
                            :label="ct.cvarios[1]"
                            :disabled="noEdit">
                          </v-checkbox>

                          <uiText
                            style="flex: 1 1 50%"
                            v-model="ct.razon[2]"
                            :label="ct.razon[1]"
                            :disabled="noEdit">
                          </uiText>
                        </div>

                        <uiText
                          v-model="ct.admoncont[2]"
                          :label="ct.admoncont[1]"
                          :disabled="noEdit">
                        </uiText>

                        <div style="conflex">
                          <uiText
                            style="flex: 1 1 50%"
                            v-model="ct.admontlf1[2]"
                            :label="ct.admontlf1[1]"
                            :disabled="noEdit">
                          </uiText>

                          <uiText
                            style="flex: 1 1 50%"
                            v-model="ct.admonfax1[2]"
                            :label="ct.admonfax1[1]"
                            :disabled="noEdit">
                          </uiText>
                        </div>

                        <uiText
                          v-model="ct.admonemail[2]"
                          :label="ct.admonemail[1]"
                          :disabled="noEdit">
                        </uiText>

                        <div style="conflex">
                          <uiText
                            style="flex: 0 0 33%"
                            v-model="ct.ban[2]"
                            :label="ct.ban[1]"
                            :disabled="noEdit">
                          </uiText>

                          <uiText
                            style="flex: 0 0 33%"
                            v-model="ct.cta[2]"
                            :label="ct.cta[1]"
                            :disabled="noEdit"
                            format="banco">
                          </uiText>

                          <uiText
                            style="flex: 0 0 33%"
                            v-model="ct.vto[2]"
                            :label="ct.vto[1]"
                            :disabled="noEdit">
                          </uiText>
                        </div>
                      <!-- </div> -->
                    </v-sheet>

                    <div class="cab">TIPO DE RELACIÓN</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <v-radio-group v-model="ct.residencia[2]" row>
                        <v-radio
                          v-for="tipo in tipoResidencia"
                          :key="tipo.id"
                          :label="tipo.label"
                          :value="tipo.id"
                          :disabled="noEdit">
                        </v-radio>
                      </v-radio-group>

                      <uiText
                        v-model="ct.ccont[2]"
                        :label="ct.ccont[1]"
                        :disabled="noEdit">
                      </uiText>

                      <div class="conflex">
                        <v-checkbox
                          style="width:20%"
                          v-bind="$checkbox"
                          v-model="ct.pdi[2]"
                          :label="ct.pdi[1]"
                          :disabled="noEdit">
                        </v-checkbox>

                        <v-select
                          style="width:60%"
                          v-bind="$select"
                          v-model="ct.tRF[2]"
                          :label="ct.tRF[1]"
                          :items="itemsFiscal"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="name">
                        </v-select>

                        <uiText
                          style="width:20%"
                          v-model="ct.pRF[2]"
                          :label="ct.pRF[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>


                    </v-sheet>
                  </div>

                  <div class="columna"  style="width:35%">
                    <div class="cab">COMPAÑÍA</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <div>
                        <ctrlF
                          :ct="ct.cia_id"
                          :edicion="!noEdit">
                        </ctrlF>
                      </div>

                      <uiObs3
                        :record="record"
                        :schema="ct"
                        :schemaComponente="ct.obs"
                        :edicion="!noEdit">
                      </uiObs3>

                      <uiObs3
                        :record="record"
                        :schema="ct"
                        :schemaComponente="ct.obs2"
                        :edicion="!noEdit">
                      </uiObs3>
                    </v-sheet>

                    <div class="conflex">
                      <div class="columna" style="width:50%">
                        <div class="cab">RELACIÓN COMERCIAL</div>
                        <v-sheet v-bind="$cfg.styles.marco">
                          <v-checkbox
                            v-bind="$checkbox"
                            v-model="ct.proveedor[2]"
                            :label="ct.proveedor[1]"
                            :disabled="noEdit">
                          </v-checkbox>

                          <v-checkbox
                            style="padding-top:12px"
                            v-bind="$checkbox"
                            v-model="ct.cliente[2]"
                            :label="ct.cliente[1]"
                            :disabled="noEdit">
                          </v-checkbox>
                        </v-sheet>
                      </div>

                      <div class="columna" style="width:50%">
                        <div class="cab">TIPO DE PERSONA</div>
                        <v-sheet v-bind="$cfg.styles.marco">
                          <v-radio-group v-model="ct.persona[2]" row>
                            <v-radio
                              v-for="tipo in tipoPersona"
                              :key="tipo.id"
                              :label="tipo.label"
                              :value="tipo.id"
                              :disabled="noEdit">
                            </v-radio>
                          </v-radio-group>
                        </v-sheet>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-sheet>


            <!-- Ventana de Componentes Extra  -->
            <component
              :is="componenteDinamico"
              syncUpdate
              :storeRaiz="storeName"
              :masterStore="storeName"
              :readOnly="readOnly"
              tipoDocumento='3'
              @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
            </component>

          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";
  import ctrlF from "@/components/ctrlF";
  import commonDocsF from "@/components/commonDocsF.vue";
  import commonFrasF from "@/components/commonFrasF.vue";

  export default {
    mixins: [mixM],
    components: {
      baseHeader,
      baseBtraMto,
      baseBtraExtra,
      dualTemplate,
      ctrlF,
      commonDocsF,
      commonFrasF
    },

    props: {},

    data() {
      return {
        stIni: {
          api: "miscM",
          name:"miscM",
          titulo:"·Miscelanea",
          recordAccess:"local",
          mView:'',
          pView:[]
        },

        tipoPersona:[],
        tipoResidencia:[],
        itemsFiscal:[],

        copiado:false,
        pegado:false,
      };
    },



    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { "accion":'documentos', "btn": "docs" },
          { "accion":'facturas', "btn": "facturas" }
        ];


        // array de Tipo de Personas
        this.tipoPersona= [
          { id: "0", label: "FÍSICA" },
          { id: "1", label: "JURÍDICA" }
        ];

        // array de Tipo de Residencias
        this.tipoResidencia= [
          { id: "0", label: "RESIDENTE" },
          { id: "1", label: "UNIÓN EUROPEA" },
          { id: "2", label: "EXTRANJERO" }
        ];

        // array de tipos de Regimen Fiscal
        this.itemsFiscal= this.$store.state.datos_iniciales.tipo_regfiscal;
      },

       // gancho final para todas las operaciones de CRUD
       crudFin() {
        this.botonerasSet();
      },

      // desahbilitamos botones extra cuando se edita
      botonerasSet() {
        this.btnSet(this.btExtCfg, 'documentos', { view: true, disabled:!this.noEdit? true : false });
        this.btnSet(this.btExtCfg, 'facturas', { view: true, disabled:!this.noEdit? true : false });        
      },

      // copia datos (solo en ver)
      copy() {
        //const hijo = this.$store.state[this.stName];
        const record = JSON.parse(
          //JSON.stringify(this.$store.state[hijo].records)
          JSON.stringify(this.record)
        );
        if (record.length == 0) {
          this.$root.$alert.open("No hay datos para copiar", "error");
          return;
        }
        this.$store.commit("data2State", {
          modulo: "datos_iniciales",
          prop: "recordsCopy",
          value: record
        });
        this.$root.$alert.open("Datos Copiados", "info", 1500);

        this.copiado=true;
        this.pegado=false;
      },

      // pega datos si ha copiado antes y en nuevo ??
      async pega() {
        let record=this.$store.state.datos_iniciales.recordsCopy;
        if (!Object.keys(record).length) {
          this.$root.$alert.open("No hay datos para pegar", "error", 1800);
          return;
        }
        console.log('***pega', record);

        await this.record2ctrl(record, this.ct, false);
        // asignacion manual de comp observaciones
        this.ct.obs[4].comp.valueAux= record.obs;
        this.ct.obs2[4].comp.valueAux= record.obs2;

        // preguntar si quiere traspasar los presupuestos - despues de grabar nuevo
        this.pegado=true;
        this.$root.$alert.open("Datos Pegados", "info", 1500);
      },

      async guardarFin() {
        //alert('guardado ... ' + this.estado);
        if (this.pegado && this.estado=='nuevo') {
          await this.añadir_aux_pregunta();
        }
        // quitamos estados copia pega
        this.copiado=this.pegado=false;
      },

      async añadir_aux_pregunta() {
        await this.$root.$alert.open("¿ AÑADIR DOCUMENTOS DESDE MISCELANEA COPIADA ?","confirm",null,
          //["NO","SUCURSALES","DOCUMENTOS","SUCURSALES+DOCUMENTOS"])
          ["NO","SI"])
          .then(r => {
            //alert(r);
            if (r) this.añadir_aux(r);
          });
      },

      // tip 1=suc,2=docs,3=pres+docs
      async añadir_aux(tip) {
        let aux1=this.$store.state.datos_iniciales.recordsCopy.id;
        let aux2=this.record.id;
        if (aux1 && aux1==aux2) return;

        // llamada API
        let param= { apiArg: ["", "cambioMisc", {}, "presupuesto"], args: { tip, aux1, aux2 } };
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        //this.$root.$alert.open("Sucursales/Documentos traspasados ...", "success", 1500);
      },


      // COMPONENTES DINAMICOS (asignación)
      //
      documentos() {
        this.componenteDinamico= 'commonDocsF';
      },

      //
      facturas() {
        this.componenteDinamico= 'commonFrasF';
      }

    },

  };
</script>
